import { SliceZone } from '@prismicio/react';
import { PrismicDocument } from '@prismicio/types';
import { captureException } from '@sentry/nextjs';
import { AnnouncementBar } from 'components/AnnoucementBar';
import MissingRequiredFields from 'components/MissingRequiredFields';
import PageNotTranslatedWrapper from 'components/PageNotTranslatedWrapper';
import { featherAggregatedRating } from 'components/reviewBadge';
import {
  ENGLISH_LOCALE_IDENTIFIER,
  PRISMIC_LOCALES,
  STRAPI_LOCALES,
} from 'constants/i18n';
import { PRISMIC_EXCLUDE_FROM_SITEMAP_TAG } from 'constants/prismic';
import { ModalWrapper } from 'context';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo, ProductJsonLd } from 'next-seo';
import ComponentManager from 'strapi/components/ComponentManager';
import { isStrapiPage, StrapiPageData } from 'strapi/types/utils';
import {
  fetchMany,
  fetchOne,
  getPathSegmentsFromPageDoc as getStrapiPathSegments,
} from 'strapi/utils/api';
import { isPageExcludedFromSiteMap } from 'strapi/utils/seo';
import { AnnouncementBarDocument } from 'types.generated';
import { isProduction } from 'util/env';
import { getNamespacesFromLocale } from 'util/getNamespacesFromLocale';
import { i18nLocaleFormatter } from 'util/i18nLocaleFormatter';
import {
  getPathSegmentsFromPageDoc as getPrismicPathSegments,
  getUidFromPathSegments,
} from 'util/prismicPathResolver';

import { createPrismicClient } from '../prismicio';
import { components } from '../slices';
import styles from './style.module.scss';

const PrismicPage = ({
  page,
  navOffset,
  announcementBar,
}: {
  page: PrismicDocument;
  navOffset: number;
  announcementBar: AnnouncementBarDocument;
}) => {
  const {
    seo_title: seoTitle,
    seo_description: seoDescription,
    og_description: ogDescription,
    og_image: ogImage,
    product_name: productName,
    aggregate_offer_low_price: aggregateOfferLowPrice,
    slices,
  } = page.data;

  if (!seoTitle || !seoDescription) {
    return (
      <div className={`pt64 ${styles.container}`}>
        <MissingRequiredFields />;
      </div>
    );
  }

  const hasOgImage = Object.keys(ogImage).length !== 0;
  const isExcludedFromSiteMap = page.tags.includes(
    PRISMIC_EXCLUDE_FROM_SITEMAP_TAG
  );

  return (
    <>
      <NextSeo
        title={seoTitle}
        description={seoDescription}
        openGraph={{
          description: ogDescription,
          images: hasOgImage
            ? [
                {
                  url: ogImage.url,
                  width: ogImage.width,
                  height: ogImage.height,
                  alt: ogImage.alt,
                },
              ]
            : [],
        }}
        noindex={isExcludedFromSiteMap && isProduction}
      />
      {productName && aggregateOfferLowPrice && (
        <ProductJsonLd
          productName={productName}
          description={ogDescription}
          aggregateRating={featherAggregatedRating}
          brand="Feather"
          aggregateOffer={{
            lowPrice: aggregateOfferLowPrice,
            priceCurrency: 'EUR',
          }}
          images={[ogImage.url]}
        />
      )}
      <div className={`pt64 ${styles.container}`}>
        {announcementBar && <AnnouncementBar document={announcementBar} />}
        <SliceZone
          slices={slices}
          components={components as never}
          context={{ navOffset }}
        />
      </div>
    </>
  );
};

const StrapiPage = ({
  page,
  announcementBar,
}: {
  page: StrapiPageData;
  announcementBar: AnnouncementBarDocument;
}) => {
  const {
    seo,
    tags,
    product_name: productName,
    aggregate_offer_low_price: aggregateOfferLowPrice,
  } = page;

  if (!seo) {
    throw new Error('Missing page metadata');
  }

  const { metaTitle, metaDescription, metaSocial, canonicalURL } = seo;
  const isExcludedFromSiteMap = isPageExcludedFromSiteMap(tags?.tags_list);

  if (!metaSocial || metaSocial.length === 0) {
    throw Error('Missing social media page preview data');
  }

  const [openGraphItem] = metaSocial;

  return (
    <>
      <NextSeo
        title={metaTitle}
        description={metaDescription}
        openGraph={{
          title: openGraphItem.title,
          description: openGraphItem.description,
          images: openGraphItem.image?.data
            ? [
                {
                  url: openGraphItem.image.data.attributes.url,
                  width: openGraphItem.image.data.attributes.width,
                  height: openGraphItem.image.data.attributes.height,
                  alt: openGraphItem.image.data.attributes.alternativeText,
                },
              ]
            : [],
        }}
        noindex={isExcludedFromSiteMap && isProduction}
        canonical={canonicalURL}
      />
      {productName && aggregateOfferLowPrice && (
        <ProductJsonLd
          productName={productName}
          description={metaDescription}
          aggregateRating={featherAggregatedRating}
          brand="Feather"
          aggregateOffer={{
            lowPrice: aggregateOfferLowPrice,
            priceCurrency: 'EUR',
          }}
          images={
            openGraphItem.image?.data
              ? [openGraphItem.image?.data.attributes.url]
              : []
          }
        />
      )}
      <div className={`pt64 ${styles.container}`}>
        {announcementBar && <AnnouncementBar document={announcementBar} />}
        <ComponentManager blocks={page.items} />
      </div>
    </>
  );
};

const Page = ({
  page,
  navOffset,
  announcementBar,
}: {
  page: null | StrapiPageData | PrismicDocument;
  navOffset: number;
  announcementBar: AnnouncementBarDocument;
}) => {
  if (!page) return <PageNotTranslatedWrapper />; // See line 247
  return (
    <ModalWrapper>
      {isStrapiPage(page) ? (
        <StrapiPage page={page} announcementBar={announcementBar} />
      ) : (
        <PrismicPage
          page={page}
          navOffset={navOffset}
          announcementBar={announcementBar}
        />
      )}
    </ModalWrapper>
  );
};

export default Page;

export const getStaticProps: GetStaticProps = async ({
  params,
  locale,
  previewData,
  preview,
}) => {
  // params.uid should be an array segmented by '/': https://nextjs.org/docs/pages/building-your-application/routing/dynamic-routes#catch-all-segments
  if (!params?.uid || typeof params.uid === 'string') return { notFound: true };

  if (!locale) throw Error('Locale was not set during the build time');

  const parsedUid = getUidFromPathSegments(params.uid);

  const prismicClient = createPrismicClient({ previewData });

  let page = null;
  try {
    page =
      (await fetchOne('pages', {
        uid: parsedUid,
        locale,
        preview,
      })) ||
      (await prismicClient.getByUID('page', parsedUid, {
        lang: locale,
        fetchLinks: [
          'comparisontable.uid',
          'comparisontable.name',
          'comparisontable.collapsible_sections',
          'comparisontable.hide_details',
          'comparisontable.slices',
        ],
      }));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    // Only case when page not found is de-de placeholder for NotTranslatedWrapper (see line 302)
    // Otherwise we're always fetching pages that are from the list returned in getStaticPaths
    // Therefore we can safely ignore the error
  }

  let announcementBar: AnnouncementBarDocument | null = null;
  try {
    announcementBar = await prismicClient.getByUID(
      'announcement_bar',
      parsedUid,
      {
        lang: locale,
      }
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    /* Silently catch the error */
  }

  const namespacesRequired = getNamespacesFromLocale(locale);

  const i18nProps = await serverSideTranslations(
    i18nLocaleFormatter(locale) || ENGLISH_LOCALE_IDENTIFIER,
    namespacesRequired
  );
  return {
    props: {
      page,
      announcementBar,
      ...i18nProps,
    },
  };
};

export const getStaticPaths = async () => {
  let allPages: (PrismicDocument | StrapiPageData)[] = [];

  try {
    const prismicPages = (
      await Promise.all(
        PRISMIC_LOCALES.map((locale) =>
          createPrismicClient().getAllByType('page', { lang: locale })
        ).flat()
      )
    ).flat();

    const strapiPages = (
      await Promise.all(
        STRAPI_LOCALES.map((locale) => fetchMany('pages', { locale })).flat()
      )
    ).flat();

    // We build de-de paths as a placeholder to render NotTranslatedWrapper later
    const emptyDePrismicPages = prismicPages
      .filter(
        (page) => page.alternate_languages.length === 0 && page.lang === 'en-de'
      )
      .map((page) => ({
        ...page,
        lang: 'de-de',
        data: {},
      }));

    allPages = [...prismicPages, ...strapiPages, ...emptyDePrismicPages];
  } catch (error) {
    captureException(error);

    throw new Error('Failed to fetch CMS pages during static generation');
  }

  const paths = allPages
    .filter((page) => !!page.uid)
    .map((page) => {
      return isStrapiPage(page)
        ? {
            params: { uid: getStrapiPathSegments(page) },
            locale: page.locale,
          }
        : {
            params: { uid: getPrismicPathSegments(page) },
            locale: page.lang,
          };
    });

  return {
    paths,
    fallback: false,
  };
};
